 .MuiListItem-button:hover {
     background-color: #99CB51 !important;
     color: rgb(255, 251, 251) !important;
   
 }

 .MuiListItem-root.Mui-focusVisible {
    background-color: #99CB51 !important;
    color: rgb(255, 251, 251) !important;
 }

 .MuiListItem-root.Mui-selected {
    background-color: transparent;
 }

 .MuiAutocomplete-option[data-focus="true"] {
    background-color: #99CB51 !important;
    color: rgb(255, 251, 251) !important;
  
}
 .MuiListItem-button {
    color: #848484 !important;
  
}

 .dropdownparent {
     position: relative;
 }

 .dropdown-menu ::before {
     content: "";
     position: absolute;
     top: -21px;
     left: 30px;
     border: 10px solid transparent;
     border-bottom-color: #eeeded;
 }
 /* .MuiBox-root{


padding: 0.3rem !important;
 } */

 .MuiInput-underline::before {
     border-bottom: 1px solid #e2e2ea !important;
 }

 .rdw-option-wrapper {
     border: none !important;
 }

 .rdw-dropdown-wrapper {
     border: none !important;
 }

 .rdw-editor-toolbar {
     padding: 1rem !important;
     gap: 1.5rem !important;
     border: none !important;
     border-top: none;
     border-left: none !important;
     border-right: none !important;
     border-bottom: 1px solid #F1F1F1 !important;
 }
 .rdw-editor-wrapper {
    box-sizing: content-box !important;
    padding: 0.3rem !important;
  }
  html{
    scrollbar-width: none;
  }